import type { AxiosRequestConfig } from 'axios'

const useApiFetch = () => {
  const { $api } = useNuxtApp()

  // TODO: refactor params to drop AxiosRequestConfig
  const fetch: <T>(config: AxiosRequestConfig, returnResponse?: boolean) => Promise<T> = config => {
    const url = config.url as string

    return $api(url, {
      method: config.method as any,
      body: config.data,
      headers: {
        'Content-Type': 'application/json',
        ...(config.headers || {}),
      },
      params: config.params,
      ...(config.baseURL ? { baseURL: config.baseURL } : {}),
    })
  }

  return {
    fetch,
  }
}
export default useApiFetch
